import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../molecules/GokenNihon/Header";
import headerImage from "../images/partnership/header.png";
import section1Image from "../images/partnership/halo_partnership.png";
import section2Image from "../images/partnership/para2.png";
import section3Image from "../images/partnership/para3.png";
import mobHeader from "../images/partnership/mobHeader.png";
import leftQ from "../images/partnership/green-quote-left.png";
import rightQ from "../images/partnership/green-quote-right.png";
import rightRQ from "../images/partnership/red-quote-right.png";
import leftRQ from "../images/partnership/red-quote-left.png";
import haloImage from "../images/partnership/HALO_logo.png";
import PartnerHeader from "../molecules/partnershipPage/v2/partnerHeader";
import PartnershipInfor from "../molecules/partnershipPage/v2/partnershipInfor";
import PartnerQuote from "../molecules/partnershipPage/v2/partnerQuote";
import WorkwithUs from "../molecules/partnershipPage/v2/workwithUs";
const halopartner = () => {
  const data = {
    header: {
      title: "Partnerships",
      para: `Partnerships matter. They open doors, expand capabilities and offerings, and deliver value.`,
      image: headerImage,
      mobImage: mobHeader,
    },
    section1: {
      title: "HALO & Goken Partnership",
      para: `The HALO Wind Tunnel, located at the Transportation Research Center (TRC) in East Liberty, Ohio, is a <b>$125M state-of-the-art facility</b>. This facility was built to usher in a new era of automotive testing providing companies the <b>data and results</b> they need to <b>stay competitive</b> in the modern landscape.`,
      link: "/insight/goken-america-partners-with-halo-wind-tunnel-in-east-liberty-ohio/",
      linkText: "Learn More About HALO",
      image: section1Image,
      logo: haloImage,
    },
    section2: {
      title: `HALO Capabilities`,
      para: `Combining HALO's <b>high level testing capabilities</b> with <b>Goken's services</b> and experienced team offers customers a <b>testing experience unique to North America</b>. Known for its acoustic array, this HALO lab system has an external <b>noise source locating accuracy of 5.0 cm</b>. As one of the world’s quietest wind tunnels, at <b>140 kph (87 mph) wind speed</b>, the noise level in the test facility is <b>less than 57 dBA.</b>`,
      image: section2Image,
    },
    section3: {
      title: "Interested in testing at HALO?",
      para: `As of 2024, Goken has partnered with HALO to handle all <b>customer engagement, operations</b>, and  <b>delivery </b>.Our team, lead by Tim Savage, has a  <b>combined testing experience of 105 years </b>. During your time at HALO, we are here to ensure your process is  <b>easy, secure, and meets your needs </b>. Get in touch with us today for more information!`,
      link: "/contactus/",
      linkText: "Contact Us",
      image: section3Image,
    },
    qouteSection: {
      list: [
        {
          text: `“When the opportunity presented itself to partner with HALO, it was a no-brainer. There’s nothing like this in North America, and pairing the HALO capabilities with Goken’s engineering expertise made perfect sense. Customers can now have a completely customized testing experience, which they have enthusiastically embraced.”`,
          textBy: "- Bill Nichols, COO - Goken America.",
          leftImage: leftQ,
          rightImage: rightQ,
          nameClass: "color-en",
        },
        {
          text: `“Goken is a valuable partner in every aspect of the customer’s experience at the HALO Wind Tunnel. From pre-test planning and logistics through data quality and delivery, the entire Goken team is focused on the one goal of delivering data that the customer can be confident in.” `,
          textBy: "- Mike Unger, Wind Tunnel Lead.",
          leftImage: leftRQ,
          rightImage: rightRQ,
          nameClass: "color-ja",
        },
      ],
    },
    ctaSection: {
      title: "Work with us",
      para: `Interested in partnering with Goken? We partner with companies from many different industries to <b class="color-en">WIN</b> together. These strategic partnerships allow us to enter new industries, provide nimble and complete services, and deliver value to our clients. Get in touch with our team to better understand the value of partnering with Goken.`,
      ctaLink: "/contactus/",
      ctaText: "Contact Us",
    },
  };
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/partnerships/"}
        description={"Partnership | HALO"}
        title="Partnerships | HALO | Goken"
      />
      <div className="partnership-sections-wrapper">
        <PartnerHeader
          title={data?.header?.title}
          para={data?.header?.para}
          image={data?.header?.image}
          mobImag={data?.header?.mobImage}
        />
        <PartnershipInfor
          title={data?.section1?.title}
          para={data?.section1?.para}
          link={data?.section1?.link}
          linkText={data?.section1?.linkText}
          image={data?.section1?.image}
          logoImage={data?.section1?.logo}
          imageFirst={false}
          className="margin-3rem"
        />
        <PartnershipInfor
          title={data?.section2?.title}
          para={data?.section2?.para}
          image={data?.section2?.image}
          imageFirst
        />
        <PartnershipInfor
          title={data?.section3?.title}
          para={data?.section3?.para}
          link={data?.section3?.link}
          linkText={data?.section3?.linkText}
          image={data?.section3?.image}
          // logoImage={data?.section3?.logo}
          imageFirst={false}
          paraFirst
        />
        <PartnerQuote
          quotes={data?.qouteSection?.list}
          // leftImage={data?.qouteSection?.leftImage}
          // rightImage={data?.qouteSection?.rightImage}
          // text={data?.qouteSection?.cta}
          // textBy={data?.qouteSection?.ctaBy}
        />
        <WorkwithUs
          title={data?.ctaSection?.title}
          para={data?.ctaSection?.para}
          link={data?.ctaSection?.ctaLink}
          linkText={data?.ctaSection?.ctaText}
        />
      </div>
    </Layout>
  );
};

export default halopartner;
